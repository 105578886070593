/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { getUser, isLoggedIn } from '@utils/authV2';
import { UserData } from '@utils/localStorageUtils';
import navigate from '@utils/navigate';
import Layout from '@widgets/Layout';
import { getTranslatedPageContent } from '@utils/contentUtils';
import { isListNotEmpty } from '@utils/commonUtils';
import Container from '@core/Container';
import Row from '@core/Row';
import Col from '@core/Col';
import { constants } from '@utils/constants';
import SEO from '@widgets/SEO';
import { SanityWidgetComponent } from '@utils/DynamicComponentConstructor';
import { setOrdersToLocalStorage, setPreorderCartToLocalStorage, setWishListToLocalStorage, setOrderProposalToLocalStorage } from '@src/utils/storageUtils';
import { initialPreOrderContentCount } from '@src/config/config';
import { getAllWishlistsNames } from '@src/apis/wishlistService';

const RenderCustomPages = props => {
  const { contents, parentIdPrefix, showAll } = props;
  const { KEY, TYPE } = constants;
  const [availableWishlists, setAvailableWishlists] = useState([]);
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const isCompeleteUrl = url.includes(UserData.getBaseUrl());

  const getWishlists = () => {
    getAllWishlistsNames().then(response => {
      if (response && response.success && isListNotEmpty(response.data)) {
        setAvailableWishlists(response.data);
      }
    });
  };

  useEffect(() => {
    if (isCompeleteUrl) {
      getWishlists();
    }
  }, []);

  return (
    <>
      {isListNotEmpty(contents)
        ? contents.map(row => (
          <Container
            key={row[KEY]}
            className={`custom-page ${row?.isFullWidth ? 'fullWidthConatiner' : ''}`}
          >
            <Row
              style={{
                marginTop: row?.spacing?.marginTop,
                marginBottom: row?.spacing?.marginBottom,
                marginLeft: row?.spacing?.marginLeft,
                marginRight: row?.spacing?.marginRight,
              }}
            >
              {isListNotEmpty(row?.columns)
                ? (row.columns.map(col => (
                  <Col
                    style={{
                      height: col?.contents.length > 1 ? '100%' : '',
                      paddingTop: col?.spacing?.marginTop || '0px',
                      paddingBottom: col?.spacing?.marginBottom || '0px',
                      paddingLeft: col?.spacing?.marginLeft || '0px',
                      paddingRight: col?.spacing?.marginRight || '0px',
                    }}
                    xs={col?.colSpan ? parseInt(col.colSpan, 10) * 3 : 12}
                    key={col[KEY]}
                  >
                    {isListNotEmpty(col?.contents)
                      ? (col.contents.map(content => (
                        <Fragment key={`customPage-${content[KEY]}`}>
                          <SanityWidgetComponent
                            widgetType={content[TYPE]}
                            content={content}
                            parentId={parentIdPrefix ? `${parentIdPrefix}-${content[KEY]}` : ''}
                            fullWidth={row.isFullWidth}
                            isCustom
                            colSpan={col.colSpan}
                            showAll={showAll}
                            availableWishlists={availableWishlists}
                            getWishlists={getWishlists}
                          />
                        </Fragment>
                      )))
                      : ''}
                  </Col>
                )))
                : ''}
            </Row>
          </Container>
        ))
        : ''}
    </>
  );
};

RenderCustomPages.defaultProps = {
  contents: [],
  parentIdPrefix: '',
  showAll: false,
};

RenderCustomPages.propTypes = {
  contents: PropTypes.array,
  parentIdPrefix: PropTypes.string,
  showAll: PropTypes.bool,
};

const CustomPages = ({ location, pageContext }) => {
  if (!isLoggedIn() && location.pathname !== '/login') {
    navigate('/login');
    return null;
  }
  if (isLoggedIn() && !location.pathname.includes(UserData.getBaseUrl())) {
    navigate(pageContext?.slug?.current);
  }
  const [initialContent, setInitialContent] = useState(null);
  const [finalContent, setFinalContent] = useState(null);
  const [showAll, setShowAll] = useState(!initialPreOrderContentCount > 0);
  const [zenDeskURL, setZendeskURL] = useState('');

  const getOrders = () => {
    const { selectedAccountUniqueId } = getUser() || {};
    if (selectedAccountUniqueId) {
      setOrdersToLocalStorage(selectedAccountUniqueId);
    }
  };

  useEffect(() => {
    getOrders();
    setWishListToLocalStorage();
    setPreorderCartToLocalStorage();
    setOrderProposalToLocalStorage();
  }, []);

  useEffect(() => {
    const contents = getTranslatedPageContent(pageContext);
    const initialContentList = [];
    const finalContentList = [];
    if (isListNotEmpty(contents?.rows) && contents?.rows.length > 0) {
      contents?.rows.forEach(row => {
        if (initialContentList.length < initialPreOrderContentCount) initialContentList.push(row);
        else finalContentList.push(row);
      });
    }
    setInitialContent(initialContentList);
    setFinalContent(finalContentList);
    setZendeskURL(pageContext?.zenDeskURL);
  }, [pageContext]);

  const parentIdPrefix = pageContext?.mozcomLocationId
    ? `${pageContext?.mozcomLocationId}-${pageContext?.mozcomBrandId}`
    : `brand-default-${pageContext?.mozcomBrandId}`;

  const showAllComponents = () => {
    if (!showAll) {
      setShowAll(true);
      UserData.setPerformance('NORMAL');
    }
  };

  useEffect(() => {
    UserData.clearPerformance();
  }, []);

  return (
    <div onMouseEnter={showAllComponents} onScroll={showAllComponents} onMouseOver={showAllComponents}>
      <Layout>
        {showAll && <SEO title={pageContext?.seoDetails?.title} zenDeskURL={zenDeskURL} /> }
        <div className='customPageOuter'>
          {isListNotEmpty(initialContent) ? <RenderCustomPages contents={initialContent} parentIdPrefix={parentIdPrefix} /> : ''}
          {isListNotEmpty(finalContent) && (showAll ? <RenderCustomPages contents={finalContent} parentIdPrefix={parentIdPrefix} /> : '')}
        </div>
      </Layout>
    </div>
  );
};

CustomPages.defaultProps = {
  location: null,
};

CustomPages.propTypes = {
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any),

};
export default CustomPages;
